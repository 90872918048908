// home.js
import React, { useEffect } from 'react';
import './home.css';
import logo from './assets/aethyra_transparent.png';

const Home = ({ onFinish }) => {
  useEffect(() => {
    // Set a timeout to hide splash screen and show main content
    const timer = setTimeout(() => {
      onFinish();
    }, 3000); // Keep splash screen visible for 3 seconds
    return () => clearTimeout(timer); // Cleanup the timer on unmount
  }, [onFinish]);

  return (
    <div className="splash-screen">
      <img src={logo} alt="Logo" className="splash-logo" />
    </div>
  );
};

export default Home;
