// MojsAnimation.js
import React, { useEffect } from "react";
import mojs from "@mojs/core";

const MojsAnimation = () => {
  useEffect(() => {
    // Initialize a simple burst animation with mo.js
    const burst = new mojs.Burst({
      left: 0,
      top: 0,
      radius: { 0: 20 },
      count: 5,
      children: {
        shape: "circle",
        radius: 7,
        fill: { black: "cream" },
        duration: 500,
      },
    });

    // Add an event listener to trigger burst animation on click
    document.addEventListener("click", function (e) {
      burst.tune({ x: e.pageX, y: e.pageY }).replay();
    });

    // Clean up event listener on component unmount
    return () => document.removeEventListener("click", () => burst);
  }, []);

  return (
    <div>
    </div>
  );
};

export default MojsAnimation;
