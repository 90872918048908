// MojsLogoAnimation.js
import React, { useEffect } from 'react';
import mojs from '@mojs/core';
import logo from './assets/aethyra_transparent.png'; // Adjust path to your logo

const MojsLogoAnimation = () => {
  useEffect(() => {
    // Define logo scaling effect
    const logoScale = new mojs.Html({
      el: '#logo',
      scale: { 0: 1 }, // Scale from 0 to full size
      opacity: { 0: 1 }, // Fade in from transparent to opaque
      duration: 2000, // Animation duration in milliseconds
      easing: 'cubic.out', // Smooth easing for clean effect
    });

    // Circle burst around the logo
    const burst = new mojs.Burst({
      parent: '#logo-container', // Attach burst to the container
      radius: { 0: 150 }, // Circle radius spread
      count: 8, // Number of particles
      children: {
        shape: 'circle',
        radius: 20, // Radius of each particle
        fill: 'white', // Keep particles white
        strokeWidth: 5,
        duration: 2000, // Burst duration
        delay: 'stagger(0, 200)', // Delay effect for particles
        easing: 'cubic.out', // Smooth easing for burst effect
      },
    });

    // Rotation effect for the logo
    const rotate = new mojs.Html({
      el: '#logo',
      angleZ: { 0: 360 }, // Rotate 360 degrees
      duration: 2500, // Duration of rotation
      easing: 'cubic.out',
    });

    // Combine animations in a timeline
    const timeline = new mojs.Timeline();
    timeline.add(logoScale, burst, rotate);
    timeline.play(); // Play the combined timeline
  }, []);

  return (
    <div
      id="logo-container"
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        overflow: 'hidden',
        backgroundColor: 'black', // Use black background for contrast
      }}
    >
      <img
        id="logo"
        src={logo}
        alt="Logo"
        style={{
          width: '150px', // Set logo size
          height: '150px',
          opacity: 0, // Initially hidden to animate fade-in
        }}
      />
    </div>
  );
};

export default MojsLogoAnimation;
