// App.js
import React, { useState } from 'react';
import './App.css';
import Navbar from './Navbar'; // Import the Navbar component
import Home from './home'; // Import the splash screen
import MojsLogoAnimation from './MojsLogoAnimation'; // Import the MojsAnimation component
import MojsAnimation from './MojsAnimation';
import MainPage from './HeroSection'; // Import the MainPage component
import HeroSection from './HeroSection';


function App() {
  const [splashFinished, setSplashFinished] = useState(false);

  return (
    
    <div className="App">
      {!splashFinished && <Home onFinish={() => setSplashFinished(true)} />} {/* Pass onFinish function */}
      {splashFinished && (
        <div>
          <Navbar /> {/* Include Navbar */}
          <HeroSection />
          <MojsAnimation />
        </div>
      )}
    </div>
  );
}

export default App;
