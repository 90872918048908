// HeroSection.js
import React, { useEffect, useState } from 'react';
import mojs from '@mojs/core';
import Particles from 'react-tsparticles';
import { loadFull } from 'tsparticles';
import { motion } from 'framer-motion';
import oNestLogo from './assets/brandmark-design-4000x4000 (1).png';
import ELogo from './assets/3E Podcast.png';
import MomsAacharLogo from './assets/image.png';
import ServiceLogo from './assets/9698e098509349.5ede1ce2d8b07-3129995009.png';
import Logo from './assets/aethyra_transparent.png'
import Pratik from './assets/DSC03794.jpg DSC03852.jpeg'
import Giri from './assets/IMG_5734.jpeg'

// Replace with your actual logo

const HeroSection = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  // Initialize tsparticles
  const particlesInit = async (main) => {
    await loadFull(main);
  };

  // Particle Options for Hero Section
  const heroParticlesOptions = {
    fullScreen: {
      enable: false,
    },
    particles: {
      number: {
        value: 150,
        density: {
          enable: true,
          value_area: 1000,
        },
      },
      color: {
        value: "#000000",
      },
      shape: {
        type: "circle",
      },
      opacity: {
        value: 0.2,
      },
      size: {
        value: 3,
        random: true,
      },
      move: {
        enable: true,
        speed: 2,
        random: true,
        straight: false,
        outModes: {
          default: "out",
        },
      },
    },
    interactivity: {
      events: {
        onhover: {
          enable: true,
          mode: "repulse",
        },
        onclick: {
          enable: true,
          mode: "push",
        },
      },
      modes: {
        repulse: {
          distance: 100,
        },
        push: {
          quantity: 4,
        },
      },
    },
    retina_detect: true,
  };

  // Particle Options for Other Sections
  const sectionParticlesOptions = {
    fullScreen: {
      enable: false,
    },
    particles: {
      number: {
        value: 100,
        density: {
          enable: true,
          value_area: 800,
        },
      },
      color: {
        value: "#fff122", // White particles
      },
      shape: {
        type: "circle",
      },
      opacity: {
        value: 0.5,
      },
      size: {
        value: 3,
        random: true,
      },
      move: {
        enable: true,
        speed: 1.5,
        random: true,
        straight: false,
        outModes: {
          default: "out",
        },
      },
    },
    interactivity: {
      events: {
        onhover: {
          enable: true,
          mode: "bubble",
        },
        onclick: {
          enable: true,
          mode: "push",
        },
      },
      modes: {
        bubble: {
          distance: 100,
          size: 6,
          duration: 2,
        },
        push: {
          quantity: 4,
        },
      },
    },
    retina_detect: true,
  };

  // Text animation
  useEffect(() => {
    new mojs.Html({
      el: '#hero-heading',
      y: { 50: 0 },
      opacity: { 0: 1 },
      duration: 1000,
      easing: 'cubic.out',
    }).play();

    new mojs.Html({
      el: '#hero-subheading',
      y: { 50: 0 },
      opacity: { 0: 1 },
      duration: 1500,
      delay: 300,
      easing: 'cubic.out',
    }).play();

    new mojs.Html({
      el: '#hero-button',
      scale: { 0: 1 },
      opacity: { 0: 1 },
      duration: 1500,
      delay: 600,
      easing: 'elastic.out',
    }).play();
  }, []);

  // Framer Motion animation variants for sections
  const sectionVariants = {
    hidden: { opacity: 0, y: 100 },
    visible: { opacity: 1, y: 0, transition: { duration: 1, ease: "easeInOut" } },
  };

  // Star rating component
  const StarRating = ({ count }) => (
    <div style={{ color: '#FFD700' }}>
      {Array.from({ length: 5 }).map((_, index) => (
        <span key={index} style={{ fontSize: '20px' }}>
          {index < count ? '★' : '☆'}
        </span>
      ))}
    </div>
  );

  // Form submission handler
  const handleFormSubmit = (e) => {
    e.preventDefault();
    // Add form submission logic here (e.g., send data to a server)
    alert('Thank you for reaching out! We will get back to you soon.');
    setFormData({ name: '', email: '', message: '' }); // Reset form fields
  };

  // Handle input change
  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <>
      {/* Hero Section */}
      <section
        style={{
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '10px 70px',
          backgroundColor: 'white',
          fontFamily: 'Tourney, sans-serif',
          height: '100vh',
          overflow: 'hidden',
        }}
      >
        {/* Particles Background */}
        <Particles 
          id="tsparticles" 
          options={heroParticlesOptions} 
          init={particlesInit} 
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            zIndex: 0,
          }}
        />

        {/* Left side: Text content */}
        <div style={{ maxWidth: '600px', zIndex: 1 }}>
          <style>
            {`
              @import url('https://fonts.googleapis.com/css2?family=Tourney:ital,wght@0,100..900;1,100..900&display=swap');
              @import url('https://fonts.googleapis.com/css2?family=Tomorrow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Tourney:ital,wght@0,100..900;1,100..900&display=swap');
              @import url('https://fonts.googleapis.com/css2?family=Edu+VIC+WA+NT+Beginner:wght@400..700&family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap');
            `}
          </style>
          <h1
            id="hero-heading"
            style={{
              position: 'relative',
              fontSize: '3em',
              marginBottom: '20px',
              marginRight: '100px',
              bottom: '60px',
              opacity: 0,
              fontFamily: 'Tourney, sans-serif',
              fontWeight: 500,
              color: 'black',
            }}
          >
            Transform Your IT Experience
          </h1>
          <p
            id="hero-subheading"
            style={{
              position: 'relative',
              fontSize: '15px',
              marginBottom: '30px',
              right: '30px',
              bottom: '20px',
              opacity: 0,
              fontFamily: 'Tomorrow, sans-serif',
              fontWeight: 500,
              color: 'black',
            }}
          >
            We provide tailored solutions for all your IT needs, ensuring seamless integration and robust support. Get started with a trusted partner today.
          </p>
          <button
            id="hero-button"
            style={{
              position: 'relative',
              padding: '15px 30px',
              fontSize: '1.2em',
              backgroundColor: 'black',
              color: 'white',
              border: 'none',
              borderRadius: '8px',
              cursor: 'pointer',
              opacity: 0,
              right: '50px',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            }}
            onClick={() => alert('Contact Us Form Coming Soon!')}
          >
            Contact Us
          </button>
        </div>

        {/* Right side: Image */}
        <div style={{ zIndex: 1 }}>
          <img
            src="https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fwww.ibm.com%2Fdesign%2Flanguage%2Fstatic%2Fffaae934e6471f8513c400e74672e20f%2F3cbba%2FGallery1.png&f=1&nofb=1&ipt=0f25f4eb387016bc645130e107da26481ccfdd12214ce1c48b01f16462c0b553&ipo=images"
            alt="IT Service"
            style={{ width: '600px', height: 'auto', borderRadius: '15px', zIndex: 1 }}
          />
        </div>
      </section>

      {/* Our Clients Section */}
      <motion.section
        initial="hidden"
        whileInView="visible"
        variants={sectionVariants}
        viewport={{ once: true, amount: 0.2 }}
        style={{
          position: 'relative',
          padding: '20px',
          backgroundColor: 'black',
          textAlign: 'center',
          overflow: 'hidden',
        }}
      >
        {/* Particles Background for Our Clients Section */}
        <Particles 
          id="clients-particles" 
          options={sectionParticlesOptions} 
          init={particlesInit} 
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            zIndex: 0,
          }}
        />

        <h2 style={{ fontFamily: 'Nunito Sans, sans-serif', fontSize: '30px', marginBottom: '30px', color: 'white', zIndex: 1 }}>
          Our Clients
        </h2>
        <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', gap: '30px', zIndex: 1 }}>
          {/* Client Logos */}
          <img src={oNestLogo} alt="Client 1" style={{ width: '150px', paddingRight: '20px' }} />
          <img src={ELogo} alt="Client 2" style={{ width: '150px', paddingRight: '20px' }} />
          <img src={MomsAacharLogo} alt="Client 3" style={{ width: '150px', paddingRight: '20px' }} />
        </div>
      </motion.section>

      {/* Reviews Section */}
      <motion.section
        initial="hidden"
        whileInView="visible"
        variants={sectionVariants}
        viewport={{ once: true, amount: 0.2 }}
        style={{
          position: 'relative',
          padding: '50px 20px',
          backgroundColor: 'black',
          textAlign: 'center',
          overflow: 'hidden',
        }}
      >
        {/* Particles Background for Reviews Section */}
        <Particles 
          id="reviews-particles" 
          options={sectionParticlesOptions} 
          init={particlesInit} 
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            zIndex: 0,
          }}
        />

        <h2 style={{ fontFamily: 'Nunito Sans, sans-serif', fontSize: '30px', marginBottom: '20px', color: 'white' }}>
          What Our Clients Say
        </h2>
        <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: '20px', zIndex: 1 }}>
          {/* Review Cards */}
          <div style={{ width: '300px', padding: '20px', boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)', borderRadius: '8px', backgroundColor: 'black', position: 'relative', color: 'white' }}>
            <img src={oNestLogo} alt="Profile" style={{ borderRadius: '50%', marginBottom: '10px', height: '50px', width: '50px' }} />
            <p>"Their service was top-notch and improved our workflow drastically!"</p>
            <p><strong>- oNest Real Estate</strong></p>
            <StarRating count={5} />
          </div>
          <div style={{ width: '300px', padding: '20px', boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)', borderRadius: '8px', backgroundColor: 'black', position: 'relative' , color: 'white' }}>
            <img src={MomsAacharLogo} alt="Profile" style={{ borderRadius: '50%', marginBottom: '10px', height: '50px', width: '50px' }} />
            <p>"Highly recommend them for their expertise and great customer support."</p>
            <p><strong>- Moms Aachar</strong></p>
            <StarRating count={4} />
          </div>
          <div style={{ width: '300px', padding: '20px', boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)', borderRadius: '8px', backgroundColor: 'black', position: 'relative', color: 'white'  }}>
          <img src={ELogo} alt="Profile" style={{ borderRadius: '50%', marginBottom: '10px', height: '50px', width: '50px' }} />
          <p>"Professional, reliable, and excellent at what they do."</p>
            <p><strong>- 3E Entertainment</strong></p>
            <StarRating count={5} />
          </div>
        </div>
      </motion.section>

      {/* Our Services Section */}
      <motion.section
        initial="hidden"
        whileInView="visible"
        variants={sectionVariants}
        viewport={{ once: true, amount: 0.2 }}
        style={{
          position: 'relative',
          padding: '50px 20px',
          backgroundColor: 'white',
          textAlign: 'center',
          overflow: 'hidden',
          display: 'flex',
          justifyContent: 'space-around',
          alignItems: 'center',
        }}
      >
        {/* Particles Background for Our Services Section */}
        <Particles 
          id="services-particles" 
          options={heroParticlesOptions} 
          init={particlesInit} 
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            zIndex: 0,
          }}
        />

        {/* Left side: Image */}
        <div style={{ width: '50%', zIndex: 1 }}>
          <img
            src={ServiceLogo}
            alt="Services"
            style={{ width: '100%', height: 'auto', borderRadius: '15px' }}
          />
        </div>

        {/* Right side: Text content */}
        <div style={{ width: '40%', zIndex: 1, textAlign: 'left' }}>
          <h2 style={{ fontFamily: 'Nunito Sans, sans-serif', fontSize: '30px', marginBottom: '20px' }}>
            Our Services
          </h2>
          <ul style={{ fontFamily: 'Nunito Sans, sans-serif', fontSize: '18px', lineHeight: '1.6' }}>
            <li><strong>Web Development:</strong> Custom website solutions tailored to your needs.</li>
            <li><strong>Digital Marketing/SEO:</strong> Boost your online presence and reach your target audience effectively.</li>
            <li><strong>Automations:</strong> Streamline your workflows and save time with smart automation.</li>
            <li><strong>IT Consulting:</strong> Get expert advice on IT infrastructure, security, and best practices.</li>
            <li><strong>Cloud Solutions:</strong> Migrate to the cloud for better scalability and efficiency.</li>
          </ul>
        </div>
      </motion.section>

      {/* Past Projects Section */}
      <motion.section
        initial="hidden"
        whileInView="visible"
        variants={sectionVariants}
        viewport={{ once: true, amount: 0.2 }}
        style={{
          position: 'relative',
          padding: '50px 20px',
          backgroundColor: '#0d0d0d', // Dark background
          textAlign: 'center',
          overflow: 'hidden',
          color: 'white', // White text for better contrast
        }}
      >
        {/* Particles Background for Past Projects Section */}
        <Particles 
          id="projects-particles" 
          options={sectionParticlesOptions} 
          init={particlesInit} 
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            zIndex: 0,
          }}
        />

        <h2 style={{ fontFamily: 'Nunito Sans, sans-serif', fontSize: '30px', marginBottom: '30px', color: 'white', zIndex: 1 }}>
          Past Projects
        </h2>
        <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', gap: '30px', zIndex: 1 }}>
          {/* Project Cards */}
          <div style={{ width: '300px', padding: '20px', boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)', borderRadius: '8px', backgroundColor: 'white', position: 'relative', color: '#0d0d0d' }}>
            <h3>Project Coming Soon</h3>
            <p>The Project will Display here</p>
            <button
              style={{
                padding: '10px 20px',
                backgroundColor: 'black',
                color: 'white',
                border: 'none',
                borderRadius: '5px',
                cursor: 'pointer',
                marginTop: '15px',
              }}
              onClick={() => window.open('https://example.com', '_blank')}
            >
              View Demo
            </button>
          </div>
          <div style={{ width: '300px', padding: '20px', boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)', borderRadius: '8px', backgroundColor: 'white', position: 'relative', color: '#0d0d0d' }}>
            <h3>Project Coming Soon</h3>
            <p>The Project will Display here</p>
            <button
              style={{
                padding: '10px 20px',
                backgroundColor: 'black',
                color: 'white',
                border: 'none',
                borderRadius: '5px',
                cursor: 'pointer',
                marginTop: '15px',
              }}
              onClick={() => window.open('https://example.com', '_blank')}
            >
              View Demo
            </button>
          </div>
          <div style={{ width: '300px', padding: '20px', boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)', borderRadius: '8px', backgroundColor: 'white', position: 'relative', color: '#0d0d0d' }}>
            <h3>Project Coming Soon</h3>
            <p>The Project will Display here</p>
            <button
              style={{
                padding: '10px 20px',
                backgroundColor: 'black',
                color: 'white',
                border: 'none',
                borderRadius: '5px',
                cursor: 'pointer',
                marginTop: '15px',
              }}
              onClick={() => window.open('https://example.com', '_blank')}
            >
              View Demo
            </button>
          </div>
        </div>
      </motion.section>

      {/* Contact Form Section */}
      <motion.section
        initial="hidden"
        whileInView="visible"
        variants={sectionVariants}
        viewport={{ once: true, amount: 0.2 }}
        style={{
          position: 'relative',
          padding: '50px 20px',
          backgroundColor: '#f0f0f0',
          textAlign: 'center',
          overflow: 'hidden',
        }}
      >
        {/* Particles Background for Contact Section */}
        <Particles 
          id="contact-particles" 
          options={sectionParticlesOptions} 
          init={particlesInit} 
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            zIndex: 0,
          }}
        />

        <h2 style={{ fontFamily: 'Nunito Sans, sans-serif', fontSize: '30px', marginBottom: '20px', color: '#0d0d0d' }}>
          Contact Us
        </h2>
        <form 
          onSubmit={handleFormSubmit} 
          style={{ 
            maxWidth: '600px', 
            margin: '0 auto', 
            padding: '20px', 
            borderRadius: '8px', 
            backgroundColor: 'white', 
            boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)', 
            zIndex: 1 
          }}
        >
          <div style={{ marginBottom: '15px' }}>
            <label htmlFor="name" style={{ display: 'block', marginBottom: '5px', fontWeight: 'bold' }}>Name</label>
            <input 
              type="text" 
              name="name" 
              value={formData.name} 
              onChange={handleInputChange} 
              required 
              style={{ 
                width: '100%', 
                padding: '10px', 
                borderRadius: '5px', 
                border: '1px solid #ccc' 
              }} 
            />
          </div>
          <div style={{ marginBottom: '15px' }}>
            <label htmlFor="email" style={{ display: 'block', marginBottom: '5px', fontWeight: 'bold' }}>Email</label>
            <input 
              type="email" 
              name="email" 
              value={formData.email} 
              onChange={handleInputChange} 
              required 
              style={{ 
                width: '100%', 
                padding: '10px', 
                borderRadius: '5px', 
                border: '1px solid #ccc' 
              }} 
            />
          </div>
          <div style={{ marginBottom: '15px' }}>
            <label htmlFor="message" style={{ display: 'block', marginBottom: '5px', fontWeight: 'bold' }}>Message</label>
            <textarea 
              name="message" 
              value={formData.message} 
              onChange={handleInputChange} 
              required 
              rows="4" 
              style={{ 
                width: '100%', 
                padding: '10px', 
                borderRadius: '5px', 
                border: '1px solid #ccc' 
              }}
            ></textarea>
          </div>
          <button 
            type="submit" 
            style={{ 
              padding: '15px 30px', 
              fontSize: '1.2em', 
              backgroundColor: 'black', 
              color: 'white', 
              border: 'none', 
              borderRadius: '8px', 
              cursor: 'pointer' 
            }}
          >
            Send Message
          </button>
        </form>

        {/* Team Information Below the Form */}
        <div style={{ marginTop: '50px', zIndex: 1 }}>
          <div style={{ display: 'flex', justifyContent: 'center', gap: '40px', flexWrap: 'wrap' }}>
            {/* Team Member 1 */}
            <div style={{ textAlign: 'left', maxWidth: '300px' }}>
              <h3>Pratik Bhusal</h3>
              <p><strong>Position:</strong> Co-Founder </p>
              <p><strong>Phone:</strong> (571) 326-8003</p>
              <p><strong>Email:</strong>bhusalpratik@aethyra.cloud</p>
            </div>

            {/* Team Member 2 */}
            <div style={{ textAlign: 'left', maxWidth: '300px' }}>
              <h3>Giriraj Koirala</h3>
              <p><strong>Position:</strong> Co-Founder </p>
              <p><strong>Phone:</strong> (703) 507-2548</p>
              <p><strong>Email:</strong> koiralagiri@aethyra.cloud</p>
            </div>
          </div>
        </div>

        {/* Company Logo at the Bottom */}
        <div style={{ marginTop: '50px', zIndex: 1 }}>
          <img src={Logo} alt="Company Logo" style={{ width: '150px', height: 'auto' }} />
        </div>
      </motion.section>
    </>
  );
};

export default HeroSection;
