// Navbar.js
import React from 'react';
import './Navbar.css';
import logo from './assets/aethyra_transparent.png'; // Ensure the path is correct


const Navbar = () => {
  return (
    <nav className="navbar">

      {/* Logo on the left */}
      <div className="nav-left">
        <img src={logo} alt="Logo" className="nav-logo" />
      </div>
      {/* Navigation Options in the center */}
      <ul className="nav-options">
        <li className="nav-item">Home</li>
        <li className="nav-item">About</li>
        <li className="nav-item">Services</li>
        <li className="nav-item">Contact</li>
      </ul>
      {/* Login and Signup Buttons on the right */}
      <div className="nav-right">
        <button className="nav-btn login-btn">Login</button>
        <button className="nav-btn signup-btn">Sign Up</button>
      </div>
    </nav>
  );
};

export default Navbar;
